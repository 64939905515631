import styled from 'styled-components';
import { Link as LinkR} from 'react-router-dom';
import { Link as LinkS} from 'react-scroll';


export const Nav = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? '#fff' : '#fff')};
    height: 80px;
  //  margin-top: -80px;
    display:flex;
    justify-content: left;
    align-items: center;
    font-size: 1re;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between; 
    height: 80px;
    z-index: 1;
    width: 100%;
    max-width: 1100px;   

    @media screen and (max-width: 960px) {
        max-width: 200px; 
    } 
`;

export const NavImg = styled.img`
    align-items: left;
    width: 90px;
    cursor: pointer;
    background: #232a34;
    margin-left: 10px;
`;

export const NavP =styled.p`
    margin-top: 10px;
    font-size: 15px;
    color: grey;
    @media screen and (max-width: 960px) {
        margin-top: 15px;
        font-size: 12px;
    }
`;

export const NavText = styled.p`
    align-items: left;
    font-size: 14px;
    color: #000;
    cursor: pointer;
    background: #fff;
    margin-top: 10px;
`;

export const NavText2 = styled.p`
    text-align: center;
    font-size: 14px;
    color: #000;
    cursor: pointer;
    background: #fff;
    margin-top: 5px;
`;



export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #0000;
  }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 960px) {
        display: none;
        color: #fff;
    }
`;

export const NavItem = styled.li`
    height: 80px;
`;

export const NavLinks = styled(LinkS)`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &:active {
        border-bottom: 3px solid skyblue;
    }
`;
