import React, {useState} from 'react'
import {
  CardImgContainer,
  CardImgP, 
  CardInfoRow, 
  CardColumn1, 
  CardColumn2, 
  CardIcon,
  CardImgH2
} from './CardImgElements'


const CardImgSection = ({
  headline,
  shiftCol,
  text,
  img
}) => {
  const [boxOpen, setBoxOpen] = useState(false);

  const changeBox = ()=> {
    setBoxOpen(!boxOpen);
  }
        
  return (
    <CardImgContainer onClick={changeBox} boxOpen={boxOpen}>
      <CardInfoRow shiftCol={shiftCol} boxOpen={boxOpen}>
        <CardColumn1>
          <CardImgH2>{headline}</CardImgH2>
          <CardIcon src={img}/>
        </CardColumn1>
        <CardColumn2>
          <CardImgP boxOpen={boxOpen}>{text}</CardImgP>
        </CardColumn2>
      </CardInfoRow>
    </CardImgContainer>
  )
}

export default CardImgSection
