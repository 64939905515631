import React, {useEffect, useState} from 'react'
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import Image from '../../images/b2bicon.jpeg';
import { 
    Nav,
    NavbarContainer,
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    NavImg,
    NavP,
    NavText2
} from './NavbarElements';

const Navbar = ({toggle}) => {
  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = ()=> {
    if(window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(()=>{
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <>
    <IconContext.Provider value={{color: '#fff'}}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavImg src={Image} type='image/jpg' to='/' onClick={toggleHome} >        
          </NavImg>  
          <NavP>
            B2B Leads
            <br></br>
            B2B Termine
            <br></br>
            B2B Verkauf
          </NavP>      
          <MobileIcon onClick={toggle}>
            <FaBars color='#000'/>
          </MobileIcon>
          <NavMenu>
              <NavItem>
                <NavLinks 
                  to='b2bupgrade' 
                  smooth={true} 
                  duration={1000} 
                  spy={true} 
                  exact='true' 
                  offset={-80} 
                > B2B Upgrade
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks 
                  to='leistungskatalog'
                  smooth={true} 
                  duration={1000} 
                  spy={true} 
                  exact='true' 
                  offset={-80} 
                > Leistungskatalog
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks 
                  to='abrechnungsmodell'
                  smooth={true} 
                  duration={1000} 
                  spy={true} 
                  exact='true' 
                  offset={-80} 
                > Abrechnungsmodell
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks 
                  to='kontakt'
                  smooth={true} 
                  duration={1000} 
                  spy={true} 
                  exact='true' 
                  offset={-80} 
                > Kontakt
                </NavLinks>
              </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar
