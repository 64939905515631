import React from 'react'

import { B2BContainer,B2BH1 } from '../B2B/B2BElements'
import Services from '../Services'
import {
    abrechnungsmodell,
    consultingpakete
} from '../AbrechnungsSection/Data'


function AbrechnungsSection() {
  return (
    <B2BContainer id='abrechnungsmodell'>
      <B2BH1>Abrechnung Projekte Telesales B2B</B2BH1>
      <Services {...abrechnungsmodell} />
      <B2BH1>Abrechnung Consulting Telesales B2B</B2BH1>
      <Services {...consultingpakete} />
    </B2BContainer>

  )
}

export default AbrechnungsSection
