import React from 'react'
import { 
    ServicesContainer, 
    ServicesWrapper, 
    ServicesCard, 
    ServicesImg    
} from './ServicesElements';

import {
    B2BH2,
    B2BP
} from '../B2B/B2BElements'


const Services=({
    headline, 
    text,
    shiftCol,
    img
}
) => {
  return ( shiftCol?
    <ServicesWrapper>
        <ServicesCard>
            <B2BH2>{headline}</B2BH2>
            <B2BP boxOpen='true>'>{text}</B2BP>
        </ServicesCard>
        <ServicesCard>
            <ServicesImg src={img} />
        </ServicesCard>
    </ServicesWrapper>
    :
    <ServicesWrapper>
        <ServicesCard>
            <ServicesImg src={img} />
        </ServicesCard>
        <ServicesCard>
            <B2BH2>{headline}</B2BH2>
            <B2BP boxOpen='true>'>{text}</B2BP> 
        </ServicesCard>
    </ServicesWrapper>
  )
}

export default Services
