import React from 'react'
import { B2BContainer, B2BH1 } from '../B2B/B2BElements'
import CardImgSection  from '../CardSectionImg'
import { CardWrapper } from '../CardSection/CardElements'
import { 
    terminierung, 
    leadsVerkaufschance,
    festverkauf,
    telefontrainings,
    salesCoaching,
    telefonskript,
    adHocBeratung,
    aufbauUndWeiterentwicklung
 } from '../LeistungsKatalog/Data'


const Leistungskatalog=({}) => {
  return (
    <B2BContainer id="leistungskatalog">
        <CardWrapper>
            <B2BH1>Projekte Telesales B2B</B2BH1>
            <CardImgSection {...terminierung}/>
            <CardImgSection {...leadsVerkaufschance}/>
            <CardImgSection {...festverkauf}/>
          </CardWrapper>
          <CardWrapper>
            <B2BH1>Consulting Telesales B2B</B2BH1>
            <CardImgSection {...telefontrainings}/>
            <CardImgSection {...salesCoaching}/>
            <CardImgSection {...telefonskript}/>
        </CardWrapper>
    </B2BContainer>
  )
}

export default Leistungskatalog
