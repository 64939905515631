import React from 'react'

import { B2BContainer } from '../B2B/B2BElements'
import { CardWrapper } from '../CardSection/CardElements'
import CardImgSection  from '../CardSectionImg'

import {
    firmenstruktur,
    team,
    kompetenz,
    aktionsKonzepte,
    referenzenUndErfahrungen
} from '../B2BUpgradeSection/Data'

export default function B2BUpgrade() {
  return (
    <B2BContainer id='b2bupgrade'>
      <CardWrapper>
          <CardImgSection {...firmenstruktur}/>
          <CardImgSection {...team}/>
          <CardImgSection {...kompetenz}/>
          <CardImgSection {...referenzenUndErfahrungen}/>
      </CardWrapper>
    </B2BContainer>
  )
}
