import styled from "styled-components";
import { Link as LinkR} from 'react-router-dom';

export const KontaktContainer = styled.div`
    margin-bottom: 20px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: auto;
    position: relative;
    z-index: 1;
`;

export const KontaktContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: relativ;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const KontaktP = styled.p`
    margin-top: 24px;
    color: #000    ;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px){
        font-size: 24px;
    }

    @media screen and (max-width: 480){
        font-size:18px;
    }
`

export const KontaktH1 = styled.h1`
    color: #000;
    font-size: 48px;
    text-align: center;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }

    @media screen and (max-width: 480){
        font-size: 32px;
    }
`;

export const KontaktH2 = styled.h2`
    text-align: center;
    font-size: 1.3rem;
    padding: 10px;
`;

export const KontaktImg = styled.img`
    height: 250px;
    width: 200px;
    opacity: 0.9;

    @media screen and (max-width: 768px) {
        height: 200px;
        width: 200px;
    }
`

export const KontaktLinks = styled(LinkR)`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-top: 50px;
    cursor: pointer;
    font-size: 2rem;

    &:active {
        border-bottom: 3px solid skyblue;
    }
`;