import styled from "styled-components";

export const CardImgWrapper = styled.div`
 //   margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 16px;
 //   padding: 0px 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr; 
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr; 
        padding: 0 20px;
    }
`

export const CardIconContainer = styled.div`
    text-align: center;
`

export const CardImgContainer = styled.div`
    background: #fff;
    display: grid;
    flex-direction: column;
    justify-content: flex-start;
    height: ${({boxOpen}) => (boxOpen ? '400px' : 'auto')};//  450opx der auto
    width: ${({boxOpen}) => (boxOpen ? '1020px' : '500px')};//  450opx der auto
   // padding-top: 5px;
    //padding: 5px;  // Abstand oben und seite
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    border-radius: 10px; // rund
    border-color: white;
    border-width: 5px; // Rand Dicke
    border-style: solid;
    overflow:  hidden;
    grid-column: 1;
    
    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr; 
        height: ${({boxOpen}) => (boxOpen ? 'auto' : '380px')};
        width: 320px;
    }
    
`;

export const CardIcon = styled.img`
    height: 250px;
    width: 300px;
`;

export const CardImgH2 = styled.h2`
    text-align: center;
    font-size: 1.2rem;
    padding: 5px;
    padding-bottom: 25px;

    @media screen and (max-width: 768px) {
        width: 320px;
    }
`;

export const CardImgP = styled.p`
    text-align: left;
    color: ${({boxOpen}) => (boxOpen ? '#000' : '#fff')};
    border: 0ch;
    font-size: 1rem;
    padding: 5px 5px;
`;

export const CardInfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(1fr, 1fr);
  //  grid-auto-columns: ${({shiftCol}) => (shiftCol ? 'minmax(auto, auto)' : 'minmax(auto,auto)' )};
    grid-template-areas: ${({shiftCol,boxOpen}) => (shiftCol && boxOpen ? `'col2 col1'` : `'col1 col2'` )};

    @media screen and (max-width: 768px) {
        grid-template-areas: 'col1 col1' 'col2 col2';
    }
`;

export const CardColumn1 = styled.div`
    grid-area: col1;
    background-color: #fff;
    width: 500px;
    padding: 0px 0px;
    text-align: center;

    @media screen and (max-width: 768px) {
        text-align: left;
    }
`;

export const CardColumn2 = styled.div`
    grid-area: col2;
    background-color: #fff;
    width: 500px;

   @media screen and (max-width: 768px) {
        width: 300px;
    }
`;

