import styled from "styled-components";

export const B2BContainer = styled.div`
    padding-bottom: 50px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    background: #fff;
/*
    @media screen and (max-width: 768) {
        height: 1100px; 
    }

    @media screen and (max-width: 480px) {
        height: 1300px; 
    }
    */
`

export const B2BH1 = styled.h1`
    color: #000;
    text-align: center;
    font-size: 2rem;
    margin-top: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`;

export const B2BContant = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: relativ;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const B2BH2 = styled.h2`
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500px; // dünner
    padding: 10px;
`;

export const B2BP = styled.p`
    text-align: left;
    color: ${({boxOpen}) => (boxOpen ? '#000' : '#fff')};
    border: 0ch;
    font-size: 1rem;
    padding: 5px 5px;
`;

export const B2Bul = styled.ul`
  //  list-style-type: circle;
 //   margin-left: 100px;
    align-items: left;
    text-align: left;
`;

export const B2Bli = styled.li`
    align-content: left;
    margin-bottom: 3px;
    text-align: left;
`;

