import React from 'react';
import Image from '../../images/iStock-473478442.jpg';

import { 
  HeroContainer, 
  HeroBg,
  HeroImg, 
  HeroContent ,
  HeroH1, 
  HeroP
} from './HeroElements';
const HeroSection = () => {
  return (
    <HeroContainer>
      <HeroBg>
        <HeroImg src={Image} type='image/jpg' />
      </HeroBg>
      <HeroContent>
        <HeroH1>Telefonvertrieb B2B Outbound</HeroH1>
        <HeroH1> Aktionen und Consulting</HeroH1>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection
