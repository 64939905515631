import styled from "styled-components";

export const InfoContainer = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
    color: #fff;
    background: ${({lightBg}) => (lightBg ? '#000' : '#fff')};

    @media screen and (max-width: 768px) {
        padding: 100px 0; 
    }
`;

export const BlaContainer = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height:400px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;

export const InfoH1 = styled.h1`
    color: #000;
    font-size: 48px;
    text-align: center;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }

    @media screen and (max-width: 480){
        font-size: 32px;
    }
`;


export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'` )};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'` )};
    }
`;

export const Column1 = styled.div`
    background-color: red;
    width: 400px;
    padding: 0 0px;
    grid-area: col1;
`;

export const Column2 = styled.div`
background-color: red;
    width: 700px;
    padding: 0 0px;
    grid-area: col2
`;

export const TextWrapper = styled.div`
    padding-top: 0;
    padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    //margin-bottom: 16px;
`;

export const Heading = styled.h1`
   // margin-bottom: 24px;
    font-size: 40px;
    line-height: 1.1;
    font-weight: 600;
    color: cornflowerblue;
    //max-width: 5700px;
   // color: ${({lightText}) => (lightText ? '#000' : '#fff')};

    @media screen and (max-width: 480px) {
        font-size: 32px; 
    }
`;

export const Subtitle = styled.p`
    max-width: auto;
   // margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: black;
   // color: ${({darkText}) => (darkText ? '#fff' : '#000')};
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;

