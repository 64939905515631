import React from 'react'

import Icon4 from '../../images/undraw_mobile_marketing_re_p77p.svg';

import imngDatePicker from '../../images/undraw/undraw_date_picker_re_r0p8.svg'
import imgBusinessChat from '../../images/undraw/undraw_business_chat_re_gg4h.svg'
import imgAllData from '../../images/undraw/undraw_all_the_data_re_hh4w.svg'
import imgOnlineFriends from '../../images/undraw/undraw_online_friends_re_eqaj.svg'
import imgDataTrends from '../../images/undraw/undraw_data_trends_re_2cdy.svg'
import imgOurSolution from '../../images/undraw/undraw_our_solution_re_8yk6.svg'
import imgChatting from '../../images/undraw/undraw_chatting_re_j55r.svg'
import imgAppInstallation from '../../images/undraw/undraw_app_installation_re_h36x.svg'
import imgLeadsLoading from '../../images/iStock-1347610711.jpg'
import imgSales from '../../images/iStock-1383864065.jpg'
import imgLeads from '../../images/iStock-466837490.jpg'


export const terminierung = {
    headline: 'Terminierung / Außendienststeuerung',
    text: terminierungText(),
    shiftCol: false,
    img: imgLeadsLoading
};

export const leadsVerkaufschance = {
    headline: 'Leads / Verkaufschance',
    text: leadsVerkaufschanceText(),
    shiftCol: true,
    img: imgLeads
}

export const festverkauf = {
    headline: 'Festverkauf',
    text: festverkaufText(),
    shiftCol: false,
    img: imgSales
}

export const telefontrainings = {
    headline: 'Telefontrainings mit Live-Telefonie',
    text: telefontrainingsText(),
    shiftCol: true,
    img: imgBusinessChat
}

export const salesCoaching = {
    headline: 'Sales-Coaching',
    text: salesCoachingText(),
    shiftCol: false,
    img: imgOurSolution
}

export const telefonskript = {
    headline: 'Telefonskript',
    text: telefonskriptText(),
    shiftCol: true,
    img: imgAppInstallation
}

export const adHocBeratung = {
    headline: 'Ad-hoc-Beratung',
    text: adHocBeratungText(),
    shiftCol: false,
    img: imgChatting
}

export const aufbauUndWeiterentwicklung = {
    headline: 'Aufbau und Weiterentwicklung telefonischer Vertriebseinheiten',
    text: aufbauUndWeiterentwicklungText(),
    shiftCol: true,
    img: imgOnlineFriends
}

export function terminierungText() {

  return (
    <>
        <p>
            Wir bedienen unterschiedliche Termintypen. Vor-Ort-Termine, Inhouse-Termine, 
            Messetermine, Online-Termine, Telefontermine.
        </p>
        <br></br>
        <p>
            Die Außendienst-Teams und interne Sales-Einheiten unserer Auftraggeber erhalten von 
            uns hochwertige Entscheidertermine mit hoher Abschlusswahrscheinlichkeit
            <p></p>
            – qualifiziert – personalisiert – interessiert.
        </p>
        <br></br>
        <p>
            Die Gesprächspartner werden im Terminierungsgespräch präzise auf den Termin 
            vorbereitet. Der Außendienst-Vertrieb hat keine kräftezehrende Anbahnungsphase, es 
            entfallen zeitaufwändige und schwierige Akquisegespräche. Die Zeitersparnis wird für 
            feste Verkaufsgespräche eingesetzt. Das steigert nachweislich Umsatz und Motivation der 
            Verkäufer-Teams.
        </p>
    </>
  )
}

export function leadsVerkaufschanceText(){
    return (
        <>
            <p>
                    Die Vertriebseinheiten unserer Auftraggeber können sich auf ihre Kernkompetenz 
                    konzentrieren – telefonische Beratung und Verkauf. Wir liefern hierfür qualifizierte und 
                    abschlussfähige Potentiale.
            </p>
            <br></br>
            <p>
                Alle Leads werden ausschließlich über den Entscheider abgewickelt. Zur Sicherstellung 
                der Lead-Qualität setzen wir bewährte Gesprächstechniken und anspruchsvolle 
                Telefonskripte ein.
            </p>
        </>
    )

}

export function festverkaufText(){
    return (
        <>
            <p>
                Mit dem richtigen Vertriebskonzept, passender Sprachfigur und ausgewählten
                Sales-Agenten können am Telefon erfolgreich Kaufabschlüsse generiert werden. Wir 
                betreuen Ihre Kunden vom Erstkontakt bis zum Vertragsabschluss.
            </p>
            <br></br>
            <p>  
                Das für diese Disziplin eingesetzte Konzept beinhaltet in der Regel mehrere 
                Aktionsstufen. Im Vorfeld erfolgt die Erarbeitung einer Kommunikationsstrategie, die auf 
                Ihr Sortiment oder Produkt ausgerichtet ist.
            </p>
        </>
    )
}

export function telefontrainingsText(){
    return (
        <>
            <p>
                Hierbei vermitteln wir sämtliche Grundlagen aus dem Telesales. Es werden Fachbegriffe 
                erläutert, Abläufe aufgezeigt und unterschiedliche Szenarien besprochen. Im Anschluss 
                führt der Trainer Telefonate mit echten Adressen durch. Es werden keine Gespräche 
                simuliert. In der letzten Trainingsphase übernehmen die Teilnehmer die Telefonate unter 
                realen Bedingungen.
            </p>
        </>
    )
}

export function salesCoachingText(){
    return (
        <>
            <p>Sie haben bereits ein intaktes Outbound Team oder einzelne Telefonverkäufer und möchten die Vertriebsstärke verbessern.</p>
            <p>Wir unterstützen Ihren telefonischen Vertrieb und setzen dabei praxisorientierte Coaching-Methoden ein. </p>
            <p>Den besten Erfolg erzielen wir, wenn wir das Vertriebs-Team oder einzelne Mitarbeiter im Live-Betrieb begleiten.</p>
            <br></br>
            <p>Nach einer Bestandsaufnahme entwickeln wir konkrete Ansätze, z.B. im Bereich Gesprächseröffnung, Nutzenargumentation, Gesprächsabschluss, etc., die direkt eingesetzt werden können.</p>
            <p>Der Erfolg ist sofort messbar.</p>
        </>
    )
}

export function telefonskriptText(){
    return (
        <>
            <p>
                Das Telefonskript ist im Telesales die wichtigste Grundlage für den Projekterfolg. Wir 
                kennen sämtliche B2B-Zielgruppen und wissen, mit welcher Ansprache der bestmögliche 
                Projekterfolg erreicht werden kann. 
            </p>
            <br></br>
            <p>
                Telefonischer B2B-Vertrieb ist äußerst anspruchsvoll und setzt voraus, dass bei der 
                Skripterstellung wesentliche Merkmale und Besonderheiten der telefonischen 
                Kommunikation berücksichtigt werden.
            </p>
            <br></br>
            <p>  
                Wir entwickeln mit Ihnen gemeinsam ein individuelles Telefonskript für die jeweilige 
                Aufgabenstellung.
            </p>
        </>
    )
}

export function adHocBeratungText(){
    return (
        <>
            <p>
                Sobald Sie feststellen, dass eine interne Telesales-Aktion nicht die gewünschten 
                Ergebnisse erzielt, unterstützen wir Sie mit unseren Erfahrungen aus dem Tagesgeschäft.
            </p>
            <br></br>
            <p>
                Nach einer kurzen Schilderung Ihrerseits, in der Sie uns Aufgabenstellung und 
                Widerstände skizzieren, erarbeiten wir mit Ihnen gemeinsam eine Optimierungsstrategie 
                und geben konkrete Handlungsempfehlungen. Eine Kurzanalyse bestimmter Parameter, 
                z.B. Adressqualität, Erreichbarkeit, Telefonskript, etc. reichen aus, um erste Maßnahmen 
                einzuleiten.
            </p>
        </>
    )
}

export function aufbauUndWeiterentwicklungText(){
    return (
        <>
            <p>
                Die Steuerung von Telesales-Einheiten ist äußerst anspruchsvoll. Führungskräfte müssen 
                unterschiedliche Faktoren berücksichtigen und kurzfristig richtige Entscheidungen 
                treffen.
            </p>
            <br></br>
            <p>
                Wir beraten Sie komplett, wenn Sie ein telefonisches Vertriebs-Team aufbauen oder 
                weiterentwickeln möchten. Neben der richtigen Einrichtung und Ausstattung empfehlen 
                wir Ihnen auf jeder Ebene die passende Lösung. Das betrifft z.B. die Bereiche 
                Personalmanagement, Kapazitätsplanung, Kennzahlen, Controlling und Skriptwesen.
            </p>
        </>
    )
}