import React from 'react';
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarWrapper,
    SidebarMenue,
    SidebarLink
} from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenue>
                <SidebarLink to='b2bupgrade' offset={-80} onClick={toggle}>
                  B2B Upgrade
                </SidebarLink>
                <SidebarLink to='leistungskatalog' offset={-80} onClick={toggle}>
                  Leistungskatalog
                </SidebarLink>
                <SidebarLink to='abrechnungsmodell' offset={-80} onClick={toggle}>
                  Abrechnungsmodell
                </SidebarLink>
                <SidebarLink to='kontakt' offset={-80} onClick={toggle}>
                  Kontakt
                </SidebarLink>
            </SidebarMenue>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
