import React from 'react'

import {InfoContainer, InfoWrapper, InfoRow, Column1, TextWrapper, Heading, Subtitle, Column2, InfoH1  } from './InfoElements'
import { 
  homeObjOne, 
  homeObjTwo,
  homeObjThree,
  homeObjFour,
  homeObjFive, 
} from '../InfoSection/Data';
import {firmenstruktur, team, kompetenz, aktionsKonzepte, referenzenUndErfahrungen} from './InfoText';
import {
    B2BH1,
    B2BContant
} from '../B2B/B2BElements'

const InfoSection = ({
    lightBg, 
    id,
    imgStart, 
    topLine, 
    lightText, 
    headline,
    darkText
}) => {
  return (
    <>
    <InfoContainer lightBg={lightBg} id={id}>
            <B2BH1>B2B Upgrade</B2BH1>
            <InfoWrapper>
                <InfoRow>
                    <Column1>
                        <TextWrapper>
                            <Heading lightText={lightText}>Firmenstruktur</Heading>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <TextWrapper>
                            <Subtitle darkText={darkText}>{firmenstruktur()}</Subtitle>
                        </TextWrapper>
                    </Column2>
                </InfoRow> 
            </InfoWrapper>
            <InfoWrapper>
                <InfoRow>
                    <Column1>
                        <TextWrapper>
                            <Subtitle darkText={darkText}>{team()}</Subtitle>  
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <TextWrapper>
                            <Heading lightText={lightText}>Team B2B Upgrade</Heading>
                        </TextWrapper>
                    </Column2>
                </InfoRow> 
            </InfoWrapper>
    </InfoContainer>
    </>
  )
}

export default InfoSection;
