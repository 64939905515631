import React, {useState} from 'react';

import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import B2BUpgrade from '../components/B2BUpgradeSection';
import TelesalesSection from '../components/TelesalesSection';
import Leistungskatalog from '../components/LeistungsKatalog';
import AbrechnungsSection from '../components/AbrechnungsSection';
import KontaktSection from '../components/KontaktSection';
import Footer from '../components/Footer';


const Home = () => {
    const [isOpen,  setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    };
    return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar toggle={toggle} />
      <HeroSection />
      <B2BUpgrade />  
      <Leistungskatalog />
      <AbrechnungsSection />
      <KontaktSection/>
      <Footer isOpen={isOpen} toggle={toggle}  />
    </>
  );
};

//       <TelesalesSection />

export default Home
