import styled from "styled-components";
import { Link as LinkR} from 'react-router-dom';

export const ImpressumContainer = styled.div`
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    position: relative;
    z-index: 1;
`
export const ImpressumContant = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: relativ;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
`;

export const ImpressumH1 = styled.h1`
    color: #000;
    text-align: center;
    font-size: 2rem;
    margin-top: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`;

export const ImpressumH2 = styled.h2`
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500px; // dünner
    padding: 50px;
`;

export const ImpressumP1 = styled.p`
    margin-top: 24px;
    color: #000    ;
    font-size: 24px;
    text-align: center;

    @media screen and (max-width: 768px){
        font-size: 24px;
    }

    @media screen and (max-width: 480){
        font-size:18px;
    }
`;

export const ImpressumP2 = styled.p`
    text-align: center;
    color: #000;
    border: 0ch;
    font-size: 1rem;
`;

export const ImpressumLinks = styled(LinkR)`
    color: #000;
    display: flex;
    align-items: left;
    text-decoration: none;
    padding-top: 50px;
    cursor: pointer;
    font-size: 2rem;

    &:active {
        border-bottom: 3px solid skyblue;
    }
`;
