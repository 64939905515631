import styled from "styled-components";

//import FontLora from "/Users/danielalbin/Sources/Privat/react/b2b/b2b/src/assets/fonts/Lora/Lora-Italic-VariableFont_wght.ttf";


export const HeroContainer = styled.div`
    background: #fff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 900px;
    position: relative;
    z-index: 1;
    /* Add: before styles*/

    @media screen and (max-width: 768px) {
        height: 600px;
      //  width: 400px;
    }

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
  /*      background: linear-gradient(
            180deg, 
            rgba(0,0,0,0.2) 0%,
            rgba(0,0,0,0.6) 100%
            ), 
            linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 
            100%);
            */
        z-index: 2;
    }


`

export const HeroBg = styled.div`
    position: absolute;
    background: white;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`;

export const HeroImg = styled.img`
    width: 100%;
    height: 100%;
   // -o-object-fit: cover;
 //   object-fit: cover;
   // background: #232a34;
    opacity: 1;
    background: white;

    @media screen and (max-width: 768px){
        width: 120%;
        height: 80%;
    }
`;

export const HeroContent = styled.div`
    z-index: 3;
    margin-top: -100px;
    padding-left: 800px;
  //  padding-top: 0px;
    //padding: 8px 24px;
   // display: flex;
    flex-direction: column;
    align-items: left;

    @media screen and (max-width: 768px){
        padding-left: 0px;
        padding-top: 400px;
    }
    
`;

export const  HeroH1 = styled.h1`
   // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;  
    color: #fff;
    font-size: 40px;
    text-align: center;

    @media screen and (max-width: 768px){
        font-size: 20px;
        text-align: center;
    }
`;

export const HeroP = styled.h1`
  //  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top: 24px;
    color: #fff;
    font-size: 40px;
    text-align: center;
    max-width: 600px;
/*
    @media screen and (max-width: 768px){
        font-size: 24px;
    }

    @media screen and (max-width: 480){
        font-size:18px;
    }
    */
`
