import React, {useState} from 'react';

import {
  ImpressumContainer,
  ImpressumContant,
  ImpressumH1,
  ImpressumH2,
  ImpressumP1,
  ImpressumP2,
  ImpressumLinks
} from './ImpressumElements'

import { 
  CloseIcon
} from '../components/Sidebar/SidebarElements';

const ImpressumPage = () => {
  window.scrollTo(0, 0)
  return (
    <ImpressumContainer id="impressum">
     <ImpressumContant>
      <ImpressumLinks to='/'> 
        <CloseIcon />
      </ImpressumLinks>
        <ImpressumH1>Impressum</ImpressumH1>     
        <ImpressumP1>
          B2B Upgrade
        </ImpressumP1>
        <ImpressumP1>
          Geschäftsbereich der Hansen Media GmbH
        </ImpressumP1>
        <ImpressumP1>
            Wupperstraße 10
            <br></br>
            14167 Berlin
        </ImpressumP1>
        <ImpressumP1>
          Telefon: 030 / 253 23 926
          <br></br>
          eMail: info@b2b-upgrade.com
        </ImpressumP1>
        <ImpressumH2>Haftungsausschuss:</ImpressumH2>
        <ImpressumP2>
          Haftung für Inhalte. Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. 
          Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr 
          übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen 
          Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als 
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde 
          Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige 
          Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von 
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. 
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten 
          Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir 
          diese Inhalte umgehend entfernen.
        </ImpressumP2>
        <ImpressumH2>Datenschutz:</ImpressumH2>
        <ImpressumP2>
          Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. 
          Soweit auf unseren Seiten personenbezogene Daten (beispielsweise, Name, Anschrift oder E-Mail-Adressen) 
          erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne 
          Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die 
          Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. 
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von im 
          Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht 
          ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. 
          Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten 
          Zusendung von Werbeinformationen, etwa durch Spammails, vor.
        </ImpressumP2>
      </ImpressumContant>
    </ImpressumContainer>
  )
}

export default ImpressumPage;
