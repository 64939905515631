import styled from "styled-components";

export const ServicesContainer = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;

 /*   @media screen and (max-width: 768) {
        height: 1100px; 
    }
    */

 /*   @media screen and (max-width: 480px) {
        height: 1300px; 
    }
    */
`

export const ServicesWrapper = styled.div`  
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;


    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr; 
        padding: 0 20px;
    }
`

export const ServicesCard = styled.div`
  //  margin-top: 30px;
  //  margin-bottom: 30px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    //max-height: 400px;
    height: 550px;
    width: 500px;
 //   padding: 30px;
 //   box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
  //  border-radius: 10px;
  //  border-color: deepskyblue;
   // border-width: 5px;
  //  border-style: solid;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr; 
    //    padding: 0 20px;
        width: auto;
        height: auto;
    //    height: 200px;
     //   width: 200px;
    }
`
export const ServicesCard2 = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    background: #000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    //max-height: 400px;
    height: 400px;
    width: 600px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr; 
        padding: 0 20px;
        width: auto;
    }
`

export const ServicesH2 = styled.h2`
    text-align: center;
    font-size: 1rem;
    margin-bottom: 10px;
`

export const ServicesP = styled.p`
    color:  deepskyblue;
    border: 0ch;
    font-size: 1rem;
    text-align: center;
`

export const ServicesImg = styled.img`
    height: 400px;
    width: 500px;

    @media screen and (max-width: 768px) {
        height: 200px;
        width: 200px;
    }
`


