import React from 'react'
import { animateScroll as scroll } from 'react-scroll';
import Image from '../../images/b2bicon.jpeg';
import { 
    FooterContainer, 
    FooterWrap, 
    FooterLinksContainer, 
    FooterLinksWrapper, 
    FooterLinkItems, 
    FooterLinkTitle , 
    FooterP,
    WebsiteRights,
    SocialMedia,
    SocialLogo,
    SocialMediaWrap
} from './FooterElements'


const Footer = ({toggle})=> {

const toggleHome = () => {
    scroll.scrollToTop();
    }

  return (
    <FooterContainer>
        <FooterWrap>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo src={Image} type='image/jpg' to='/' onClick={toggleHome}>
                    </SocialLogo>
                    <FooterP>info@b2b-upgrade.com</FooterP>
                    <FooterP>030 / 253 23 926</FooterP>
                    <WebsiteRights>BSB Upgrade © {new Date().getFullYear()} All rights reserved.
                    </WebsiteRights>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
/*
<SocialLogo src={Image} type='image/jpg' to='/' onClick={toggleHome}> 
</SocialLogo>
*/
