import React from 'react'
import styled from "styled-components";

import {
    B2Bul,
    B2Bli   
} from '../B2B/B2BElements'


import imgMobileMarketing from '../../images/undraw/undraw_mobile_marketing_re_p77p.svg';
import imgStructure from '../../images/undraw/undraw_career_development_re_sv91.svg'
import imgExperts from '../../images/undraw/undraw_experts_re_i40h.svg'
import imgPreferences from '../../images/undraw/undraw_set_preferences_kwia.svg'
import imgIdeas from '../../images/undraw/undraw_ideas_flow_re_bmea.svg'
import imgBusiness from '../../images/undraw/undraw_business_decisions_re_84ag.svg'
import imgTeamSpirit from '../../images/undraw/undraw_team_spirit_re_yl1v.svg'


export const ImgX = styled.img`
    height: 200px;
    width: 170px;
    margin-top: 10px;
    margin-left: 10px;
`;

export const firmenstruktur = {
    headline: 'Firmenstruktur',
    text: firmenstrukturText(),
    shiftCol: true,
    heightConst: '450px',
    img: imgStructure
}

export const team = {
    headline: 'Ansprechpartner',
    text: teamText(),
    shiftCol: false,
    img: imgExperts
}

export const kompetenz = {
    headline: 'Kompetenz',
    text: kompetenzText(),
    shiftCol: true,
    img: imgTeamSpirit
}

export const aktionsKonzepte = {
    headline: 'Aktions-Konzepte',
    text: aktionsKonzepteText(),
    shiftCol: false,
    img: imgMobileMarketing
}

export const referenzenUndErfahrungen = {
    headline: 'Referenzen',
    text: referenzenUndErfahrungenText(),
    shiftCol: false,
    img: imgBusiness
}



export function firmenstrukturText() {
  return (
    <>
        <p>
            Wir sind eine Telesales-Einheit mit ausgeprägter Vertriebskompetenz im 
            B2B Outbound und Ausrichtung auf Telesales-Projekte mit Umsatzsteigerungspotential.
        </p>
        <p>
            Im Fokus von B2B Upgrade steht immer die projektbezogene Umsatz- und
            Performancesteigerung unserer Auftraggeber.
        </p>
        <br></br>
        <p>
            Die telefonische Vorbereitung für Verkaufsabschlüsse und der telefonische Festverkauf sind
            dabei wesentliche Disziplinen.
        </p>
        <br></br>
        <p>    
            Des Weiteren bieten wir erfolgsbewährte Coachings und Telefontrainings mit Live-Telefonie
            an. Zusätzlich unterstützen und beraten wir unsere Kunden beim Aufbau und bei der
            Weiterentwicklung von Inside-Sales-Einheiten und Call Center.
        </p>
    </>
  )
}

export function teamText(){
    return (
        <>
            <p>
                Marco Sladic
                <br></br>
                Diplom-Kaufmann (FH) 
            </p>
            <br></br>
            <p>
                Hauptverantwortung 
                <br></br>
                Aktionssteuerung 
                <br></br>
                Kundenbetruung
                <br></br>
                Telefontrainer 
            </p>
            <br></br>
            <p>
                Die Kernkompetenz von Herrn Sladic liegt eindeutig im B2B Telesales für den Outbound-Bereich. 
                In diesem Aktionsfeld ist er seit vielen Jahren in leitender Funktion aktiv und sehr erfolgreich. 
                Kunden sind von seinem Einsatz, dem Vertriebsgedanken und der Handlungsweise voll überzeugt und 
                vertrauen auf seine Empfehlungen. Der vertriebsorientierte Ansatz im B2B Outbound und die 
                verschiedenen Aktionstypen motivieren ihn täglich, für die Kunden den bestmöglichen Erfolg und 
                langfristige Umsatzpotentiale zu erzielen.
            </p>
        </>
    )
}

export function kompetenzText(){
    return (
        <>
            <p>
                Wir verfügen über langjährige Führungserfahrung und Qualifikationen im 
                B2B-Outbound-Bereich als Projektleitung, Call-Center-Leitung und Geschäftsführung. 
            </p>
            <br></br>
            <p>
                Jeder von uns hat eine eigene Spezialisierung mit vertrieblicher Komponente
                und verfügt über jahrelange Erfahrung in Ansprache und Akquise aller gängigen B2B Zielgruppen.
            </p>
        </>
    )
}

export function aktionsKonzepteText(){
    return (
        <>
            <p>
                Für jeden Aktionstyp und für jede Aufgabenstellung entwickeln wir gemeinsam mit dem 
                Kunden ein geschlossenes Aktions-Konzept. Dabei setzen wir verschiedene 
                Verkaufsmethoden ein, die bereits erfolgreich angewendet wurden.
            </p>
            <p>
                Im Vorfeld werden sämtliche Aktionsparameter wie Aufgabenstellung, Angebot, Adressen, 
                Zielgruppe, Zielperson, Aktionszeitraum, etc. definiert und abgestimmt. Im Anschluss 
                erstellen wir die passende Vorgehensweise und ein erstes Telefonskript.
            </p>
            <br></br>
            <p>
                Alle Konzepte sind für die Bearbeitung aktiver Kunden und für die Neukundenakquise anwendbar.
            </p>
        </>
    )
}

export function referenzenUndErfahrungenText(){
    return (
        <>
            <p>
                Marco Sladic leitete zum Beispiel ein Projekt zur B2B Neukundenterminierung, bei dem sein 
                Team und er eine Auszeichnung als beste Agentur von insgesamt acht Agenturen erhalten 
                haben. Berücksichtigt wurden folgende Kriterien:
            </p>
            <br></br>
                <B2Bul>
                    <B2Bli>Qualität der vermittelten Beratungstermine </B2Bli>
                    <B2Bli>Anzahl der vermittelten Beratungstermine </B2Bli>
                    <B2Bli>Terminquote </B2Bli>
                </B2Bul>
            <br></br>
            <p>
                Im persönlichen Gespräch benennen wir Ihnen gerne konkrete Referenzen. Bitte sprechen Sie uns an.
            </p>
        </>
    )
}
