export const homeObjOne = {
    id: 'b2bupgrade',
    lightBg: false,
    lightText: true,
    lightTextDesc:true,
    topLine: 'Firmenstruktur',
    headline: 'Firmenstruktur',
    textFirmenstruktur: 
        'Wir sind eine Telesales-Agentur mit ausgeprägter Vertriebskompetenz im'+
        'B2B Outbound und Ausrichtung auf Telesales-Projekte mit Umsatzsteigerungspotential.'+
        'Im Fokus von B2B Upgrade steht immer die projektbezogene Umsatz- und'+
        'Performancesteigerung unserer Auftraggeber.'+
        'Die telefonische Vorbereitung für Verkaufsabschlüsse und der telefonische Festverkauf sind'+
        'dabei wesentliche Disziplinen. Wir konzentrieren uns auf Telesales-Projekte und'+
        'Consulting-Dienstleistungen, bei denen der Verkaufsabschluss als oberstes Projektziel'+
        'definiert ist.'+
        'Des Weiteren bieten wir erfolgsbewährte Coachings und Telefontrainings mit Live-Telefonie'+
        'an. Zusätzlich unterstützen und beraten wir unsere Kunden beim Aufbau und bei der'+
        'Weiterentwicklung von Inside-Sales-Einheiten und Call Center.'+
        'Die Entwicklung individueller und erfolgreicher Telefonskripte gehört ebenfalls zu unseren Kernaufgaben.',
    textTeam: 'Marco Sladic Profil und Aufgabenbereiche >> folgt'+
        'Hans-Otto Blume >> Spezialagent'+
        'N.N. >> in Gesprächen'+
        'N.N. >> in Gesprächen',
    textKompetenz: 'Wir verfügen über langjährige Führungserfahrung und Qualifikationen im B2B-Outbound-Bereich als Projektleitung, Call-Center-Leitung und Geschäftsführung. Jeder von uns hat eine eigene Spezialisierung mit vertrieblicher Komponente'+
        'und verfügt über mehr als 20 Jahre Erfahrung in Ansprache und Akquise aller gängigen B2B Zielgruppen.'+
        'Dieses wertvolle Wissen setzen wir gebündelt und fokussiert für unsere Kunden ein.',
    textAktionskonzepte: 'Für jeden Aktionstyp und für jede Aufgabenstellung entwickeln wir gemeinsam mit dem Kunden ein geschlossenes Aktions-Konzept. Dabei setzen wir verschiedene Verkaufsmethoden ein, die bereits erfolgreich angewendet wurden.'+
        'Im Vorfeld werden sämtliche Aktionsparameter wie Aufgabenstellung, Angebot, Adressen, Zielgruppe, Zielperson, Aktionszeitraum, etc. definiert und abgestimmt. Im Anschluss erstellen wir die passende Vorgehensweise und ein erstes Telefonskript.'+
        'Alle Konzepte sind für die Bearbeitung aktiver Kunden und für die Neukundenakquise anwendbar.',
    textReferenzenUndErfahrungen: 'Das Führungspersonal von B2B Upgrade besteht ausschließlich aus Personen, die in der Vergangenheit in mehreren B2B-Outbound-Agenturen tätig waren. Daraus haben sich vertrauensvolle Kundenbeziehungen ergeben, die weiterhin Bestand haben.Wir streben immer den bestmöglichen Erfolg und höchste Ausschöpfung für unsere Auftraggeber an. Marco Sladic leitete zum Beispiel ein Projekt zur B2B Neukundenterminierung, bei dem sein Team und er eine Auszeichnung als beste Agentur von insgesamt acht Agenturen erhalten haben. Berücksichtigt wurden folgende Kriterien: - Qualität der vermittelten Beratungstermine - Anzahl der vermittelten Beratungstermine - Terminquote Im persönlichen Gespräch benennen wir Ihnen gerne konkrete Referenzen. Bitte sprechen Sie uns an.',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('../../images/sv-1.svg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjTwo = {
    id: 'b2bupgrade',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Team',
    headline: 'Team',
    description: 'Marco Sladic Profil und Aufgabenbereiche >> folgt'+
    'Hans-Otto Blume >> Spezialagent'+
    'N.N. >> in Gesprächen'+
    'N.N. >> in Gesprächen',
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require('../../images/sv-1.svg'),
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true
};

export const homeObjThree = {
    id: 'b2bupgrade',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Kompetenz',
    headline: 'Kompetenz',
    description: 'Wir verfügen über langjährige Führungserfahrung und Qualifikationen im B2B-Outbound-Bereich als Projektleitung, Call-Center-Leitung und Geschäftsführung. Jeder von uns hat eine eigene Spezialisierung mit vertrieblicher Komponente'+
    'und verfügt über mehr als 20 Jahre Erfahrung in Ansprache und Akquise aller gängigen B2B Zielgruppen.'+
    'Dieses wertvolle Wissen setzen wir gebündelt und fokussiert für unsere Kunden ein.',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('../../images/sv-1.svg'),
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true
};

export const homeObjFour = {
    id: 'abrechnungsmodell',
    lightBg: false,
    lightText: true,
    lightTextDesc:true,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transaction with zero fees',
    description: 'Get access to our exclusive app',
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require('../../images/sv-1.svg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjFive = {
    id: 'kontakt',
    lightBg: false,
    lightText: true,
    lightTextDesc:true,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transaction with zero fees',
    description: 'Get access to our exclusive app',
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require('../../images/sv-1.svg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
};
