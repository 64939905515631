import React from 'react'

import{
    KontaktContainer,
    KontaktContent,
    KontaktImg,
    KontaktP,
    KontaktH2,
    KontaktLinks
} from './KontaktElements'

import imgMarco from '../../images/Marco_Sladic_Foto.jpg'
import imgSteinWand from'../../images/steinwand.jpg';

function KontaktSection() {
  return (
    <KontaktContainer id="kontakt">
        <KontaktContent>
            <KontaktP>
                Sprechen Sie uns gerne an, Sie erhalten kurzfristig ein unverbindliches Angebot oder 
                vereinbaren Sie mit uns einen Gesprächstermin.
            </KontaktP>
            <br></br>
            <KontaktImg src={imgMarco} />
            <KontaktP>Marco Sladic</KontaktP>
            <KontaktP>
                B2B Upgrade
            </KontaktP>
            <KontaktP>
                030 / 253 23 926
                <br></br>
                info@b2b-upgrade.com
            </KontaktP>
            <KontaktP>
                Geschäftsbereich der Hansen Media GmbH
            </KontaktP>
            <KontaktP>
                Wupperstraße 10
                <br></br>
                14167 Berlin
            </KontaktP>
            <KontaktLinks to='/impressum' exact='true'> Impressum
            </KontaktLinks>
        </KontaktContent>
    </KontaktContainer>
  )
}

export default KontaktSection
