import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages';
import ImpressumPage from './pages/impressum';

function App() {
  return (
    <Router>
      <Routes>
       <Route path='/' element={<Home/>} />
       <Route path='/impressum' element={<ImpressumPage/>} />
      </Routes> 
    </Router>
  );
}

export default App;
