import React from 'react';

import {
    B2Bul,
    B2Bli   
} from '../B2B/B2BElements'


import imgMeetingRoom from '../../images/meeting_room.jpg'
import imgBGateSide from '../../images/pexels-adrien-olichon-2817497.jpg'


export const vertriebsinstrument = {
    headline: 'Vertriebsinstrument B2B Telesales',
    text : vertriebsinstrumentText(),
    shiftCol: true,
    img: imgBGateSide
};

export const vorteile = {
    headline: 'Vorteile B2B Telesales',
    text : vorteileText(),
    shiftCol: true,
    img: imgMeetingRoom
};

export function vertriebsinstrumentText(){
    return (
        <>
            Im Rahmen der Neukundengewinnung, Reaktivierung oder Kundenbindung werden
            unterschiedliche Vertriebsinstrumente verwendet. Das Telefon wird verstärkt 
            eingesetzt, um Verkäufe einzuleiten, z.B. in Form qualifizierter 
            Leads (Verkaufschance) oder über eine hochwertige Außendienstterminierung.
            Mit der richtigen Methode lassen sich auch telefonische Festverkäufe abwickeln.  
        </>
    )
}

export function vorteileText(){
    return (
        <>
          <B2Bul>
            <B2Bli>Identifikation der richtigen Zielperson</B2Bli>
            <B2Bli>persönliche Ansprache</B2Bli>
            <B2Bli>Mobilisierung der Ansprechpartner</B2Bli>
            <B2Bli>kein Streuverlust</B2Bli>
            <B2Bli>Rücklieferung qualifizierter Datensätze</B2Bli>
            <B2Bli>Anwendung von Up- und Cross-Selling</B2Bli>
            <B2Bli>Live-Controlling und Statistiken</B2Bli>
            <B2Bli>zeitliche Steuerung der Aktionen</B2Bli>
            <B2Bli>
                Bewertung wichtiger Aktionsparameter, z.B. Affinität, Adressqualität, Erreichbarkeit,Gesprächsbereitschaft, Durchdringung, etc.
            </B2Bli>
            <B2Bli>Einsatz bewährter Telefonskripte und Gesprächstechniken</B2Bli>
            <B2Bli>kurzfristige Änderungen bzw. Anpassung der Gesprächsphasen</B2Bli>
            <B2Bli>Flexibilität im Personaleinsatz</B2Bli>
            <B2Bli>Wiedervorlagen-Management</B2Bli>
          </B2Bul>
        </>
    )
}