import React from 'react'

import {
    B2Bul,
    B2Bli   
} from '../B2B/B2BElements'


import imgMeetingRoom from '../../images/meeting_room.jpg'
import imgSteinWand from'../../images/steinwand.jpg';
import imgBGateSide from '../../images/pexels-adrien-olichon-2817497.jpg'
import imgCoffee from'../../images/pexels-jason-villanueva-851555.jpg'
import imgMacAndIphone from '../../images/pexels-life-of-pix-7974.jpg'
import imgMauerGrau from '../../images/pexels-pixabay-220182.jpg'


export const abrechnungsmodell={
    headline: '',
    text: abrechnungsmodellText(),
    shiftCol: true,
    img: imgSteinWand
}

export const consultingpakete={
    headline: '',
    text: consultingpaketeText(),
    shiftCol: true,
    img: imgCoffee
}

export function abrechnungsmodellText() {
    return(
        <>
            <p>
                Telefonaktionen werden über einen Pauschalpreis oder auf Provisionsbasis abgerechnet.
                Im Vorfeld erfolgt zunächst ein Aktionstest. Hierfür fällt eine Einmalpauschale für das 
                Starterpaket an, in dem mindestens 10 Agentenstunden enthalten sind. Auf Basis der Ergebnisse
                 wird für den Fortlauf der Aktion kurzfristig ein Angebot erstellt. 
            </p>
        </>
    )
}

export function consultingpaketeText() {
    return(
        <>
            <p>
                Im Consulting-Bereich wird nach Definition der Aufgabenstellung eine Pauschale festgesetzt.
                B2B Upgrade erstellt ein Angebot und erläutert im Detail, welche Leistungen das jeweilige 
                Consulting-Paket beinhaltet. 
            </p>
            <br></br>
            <p>
                Nutzen Sie den großen Vorteil und lassen Sie über uns auch Einzelpersonen ausbilden. 
                Somit können Sie gezielt Mitarbeiter einsetzen, die auf unserem Niveau für Ihren 
                Vertrieb telefonisch aktiv sind. Gerne erstellen wir auch hierfür ein spezielles Angebot. 
            </p>
        </>
    )
}