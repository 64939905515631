import styled from "styled-components";


/*
export const ContentContainer = styled.div`
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    margin-top: 40px;
    margin-bottom: 40px;
    color: #000;
    background: gray;

    @media screen and (max-width: 768px) {
        padding: 100px 0; 
    }
`;
*/

export const CardContainer = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    //align-items: center;
    border-radius: 10px; // rund
    // color: ${({darkText}) => (darkText ? '#fff' : '#000')};
    height: ${({boxOpen}) => (boxOpen ? '450px' : '70px')};//  450opx der auto
    width: 450px;
    padding: 10px;  // Abstand oben und seite
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    border-color: white;
    border-width: 5px; // Rand Dicke
    border-style: solid;
    overflow:  hidden;
    
    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr; 
        padding: 0 20px;
        width: auto;
    }
`;

export const CardH2 = styled.h2`
    text-align: center;
   // align-items: baseline;
    font-size: 1rem;
  //  margin-top: 15px;
  //  margin-bottom: 15px;
   padding: 15px;
`;

export const CardP = styled.p`
    text-align: left;
    color: ${({boxOpen}) => (boxOpen ? '#000' : '#fff')};
    border: 0ch;
    font-size: 1rem;
`;

export const CardWrapper = styled.div`
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 10px 10px 10px;


    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr; 
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr; 
        padding: 0 20px;
    }
`
